import React, { useState } from "react";
import NavMenu from "../component/navMenu";
import Footer from "../component/footer";
import { Button, Modal, Collapse, Form } from "react-bootstrap";
import images from "../data/images";
import ImageUploader from "../test/ImageUploader";

function PrivateArea() {
    return (
        <div style={{ backgroundColor: "#131419" }} className="App">
            <NavMenu />
            <Content />
            <Footer />
        </div>
    );
}

function Content() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modifiedImage, setModifiedImage] = useState({});
    const [open, setOpen] = useState(true); // Collapse sempre aperto per 2D Art

    const uniqueSubCategories = Array.from(
        new Set(images.flatMap((img) => img.filter))
    );

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setModifiedImage({ ...image });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
        setModifiedImage({});
    };

    const handleInputChange = (field, value) => {
        setModifiedImage({ ...modifiedImage, [field]: value });
    };

    const handleOtherImagesChange = (index, field, value) => {
        const updatedOtherImages = [...modifiedImage.otherImages];
        updatedOtherImages[index][field] = value;
        setModifiedImage({ ...modifiedImage, otherImages: updatedOtherImages });
    };

    const toggleSubCategory = (subcategory) => {
        const updatedFilters = modifiedImage.filter.includes(subcategory)
            ? modifiedImage.filter.filter((cat) => cat !== subcategory)
            : [...modifiedImage.filter, subcategory];
        setModifiedImage({ ...modifiedImage, filter: updatedFilters });
    };

    return (
        <div
            className="d-flex flex-column"
            style={{
                minHeight: "70vh",
                overflowX: "hidden",
                backgroundColor: "#37283D",
                color: "white",
            }}
        >
            <h1 className="mt-3">Private Area - 2D Art</h1>

            <div className="p-3 m-3 border" style={{ borderRadius: "10px" }}>
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(!open)}
                >
                    <h2>2D Art</h2>
                    <span>{open ? "-" : "+"}</span>
                </div>

                <Collapse in={open}>
                    <div>
                        <div
                            className="d-flex flex-wrap p-3"
                            style={{
                                justifyContent: "center",
                                gap: "10px",
                            }}
                        >
                            {images.map((img) => (
                                <div
                                    key={img.image}
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleImageClick(img)}
                                >
                                    <img
                                        src={img.image}
                                        alt={img.title}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            borderRadius: "10px",
                                            border: "1px solid #fff",
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Collapse>


                <ImageUploader />
            </div>

            {/* Modale per la modifica */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Modifica immagine</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        backgroundColor: "white", // Sfondo bianco
                        borderRadius: "10px",
                    }}
                >
                    {selectedImage && (
                        <>
                            <div className="text-center mb-3">
                                <img
                                    src={selectedImage.image}
                                    alt={selectedImage.title}
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                    }}
                                />
                            </div>
                            <div>
                                <label>Titolo:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={modifiedImage.title}
                                    onChange={(e) =>
                                        handleInputChange("title", e.target.value)
                                    }
                                />
                            </div>
                            <div className="mt-3">
                                <label>Descrizione:</label>
                                <textarea
                                    className="form-control"
                                    value={modifiedImage.description}
                                    onChange={(e) =>
                                        handleInputChange("description", e.target.value)
                                    }
                                ></textarea>
                            </div>
                            <div className="mt-3">
                                <label>Sotto-categorie:</label>
                                <div className="d-flex flex-wrap mt-2">
                                    {uniqueSubCategories.map((subcategory, idx) => (
                                        <Form.Check
                                            key={idx}
                                            type="checkbox"
                                            label={subcategory}
                                            checked={modifiedImage.filter.includes(subcategory)}
                                            onChange={() => toggleSubCategory(subcategory)}
                                            className="me-3"
                                        />
                                    ))}
                                </div>
                            </div>
                            {modifiedImage.otherImages.length > 0 && (
                                <>
                                    <h6 className="mt-3">Altre immagini:</h6>
                                    {modifiedImage.otherImages.map((otherImg, index) => (
                                        <div key={index} className="mt-2">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={otherImg.image}
                                                    alt={otherImg.title}
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        borderRadius: "5px",
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                <div style={{ flex: 1 }}>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-2"
                                                        placeholder="Nome"
                                                        value={otherImg.title}
                                                        onChange={(e) =>
                                                            handleOtherImagesChange(
                                                                index,
                                                                "title",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Descrizione"
                                                        value={otherImg.description}
                                                        onChange={(e) =>
                                                            handleOtherImagesChange(
                                                                index,
                                                                "description",
                                                                e.target.value
                                                            )
                                                        }
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer
                    style={{
                        backgroundColor: "white",
                    }}
                >
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Chiudi
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            alert("Modifiche salvate!");
                            handleCloseModal();
                        }}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PrivateArea;
