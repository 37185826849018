import Footer from "./component/footer"
import NavMenu from "./component/navMenu"
import React from "react";

function Commissions() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}

function Content() {

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    return (
        <div className='row' style={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden', backgroundColor: '#37283D' }}>
            <div className="col h-100 d-flex flex-column justify-content-center">
                <h1 className="text-white">COMING SOON...</h1>
                <div className="row h-100">
                    <div className="col-6 h-100">
                        <iframe
                            id='kofiframe'
                            src='https://ko-fi.com/asterlocke/?hidefeed=true&widget=true&embed=true&preview=true'
                            style={{ border: 'none', width: '60%', padding: '4px', background: '#f9f9f9', height: '100%' }}
                            title='asterlocke'
                        ></iframe>
                    </div>

                </div>
            </div>
        </div >
    )

}

export default Commissions