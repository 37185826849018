const images = [
    {
        image: '',
        video: '/art/VIDEO/video_bedroom.mp4',
        title: 'Delnaavi Apache Helerel Dijkstra',
        description: 'Lorem',
        filter: ['ILLUSTRATION'],
        otherImages: [
            {
                image: '/art/naavi.gif',
                title: 'Shira'
            }
        ]
    },
    {
        image: '',
        video: '/art/VIDEO/room_correct-order.mp4',
        title: 'Delnaavi Apache Helerel Dijkstra',
        description: 'Lorem',
        filter: ['ILLUSTRATION'],
        otherImages: []
    },
    {
        image: '',
        video: '/art/VIDEO/bedroom_render.mp4',
        title: 'Delnaavi Apache Helerel Dijkstra',
        description: 'Lorem',
        filter: ['ILLUSTRATION'],
        otherImages: []
    },
    {
        image: '',
        video: '/art/VIDEO/pozzo.mp4',
        title: 'Delnaavi Apache Helerel Dijkstra',
        description: 'Lorem',
        filter: ['ILLUSTRATION'],
        otherImages: []
    },
    {
        image: '',
        video: '/art/VIDEO/avala.mp4',
        title: 'Delnaavi Apache Helerel Dijkstra',
        description: 'Lorem',
        filter: ['ILLUSTRATION'],
        otherImages: []
    },
    {
        image: '/art/CONCEPTART/Ash_Fight.png',
        video: '',
        title: 'Delnaavi Apache Helerel Dijkstra',
        description: 'Lorem',
        filter: ['ILLUSTRATION'],
        otherImages: []
    }

]

export default images