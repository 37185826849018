import Footer from "../component/footer";
import NavMenu from "../component/navMenu";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Images from "../data/images";
import filter from "../data/filter2d";
import ImageModal from "../component/imgModal";

const images = Images;
const filterList = filter.filters;
const filterDescriptions = filter.array;

function Art2d() {
    return (
        <div style={{ backgroundColor: '#131419' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    );
}

function Content() {
    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const [state, setState] = useState({
        filter: 'ALL',
        filterDescriptions: filterDescriptions
    });

    const getFilteredImages = () => {
        if (state.filter === 'ALL' || state.filter === '') {
            return images;
        } else {
            return images.filter(img => img.filter.includes(state.filter));
        }
    };

    const DescriptionFilter = () => {
        const result = state.filterDescriptions.find(obj => obj.filter === state.filter);
        return (<p className="text-white">{result?.description}</p>);
    };

    return (
        <div className="d-flex flex-column" style={{ minHeight: '70vh', overflowX: 'hidden', backgroundColor: '#37283D' }}>
            <div style={{ objectFit: 'cover', height: '10vh', backgroundImage: "url('/art/2d.jpg')" }}>
                <div style={centerMiddle}>
                    <h1 className="p-0 m-0 text-white">2D ART</h1>
                </div>
            </div>
            <ButtonGroup callback={(filter) => setState({ ...state, filter })} />
            <DescriptionFilter />
            <Gallery array={getFilteredImages()} />
        </div>
    );
}

function ButtonGroup(props) {
    return (
        <div className="ButtonGroup py-3">
            {filterList.map((filtro, i) => (
                <React.Fragment key={i}>
                    <input defaultChecked={i === 0} className="FilterRadio" id={'filter' + i} type="radio" name='filter' onChange={() => props.callback(filtro)} />
                    <label className="FilterLabel px-4 py-1 my-1" htmlFor={'filter' + i}>{filtro}</label>
                </React.Fragment>
            ))}
        </div>
    );
}

function Gallery(props) {
    const grid = () => {
        const columns = [[], [], [], []];
        props.array.forEach((img, i) => {
            columns[i % 4].push(<ImageModal key={img.image} img={img} />);
        });
        return (
            <div className="row">
                {columns.map((col, i) => (
                    <div key={i} className="col-12 col-lg p-2">
                        {col}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div style={{ paddingLeft: '5em', paddingRight: '5em', minHeight: '100vh' }}>
            {grid()}
        </div>
    );
}

export default Art2d;
