
import NavMenu from './component/navMenu';
import Footer from './component/footer'
import { useNavigate } from 'react-router-dom';
import { delay, motion } from "framer-motion"



function Portfolio() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} className='App'>
            <NavMenu />

            <Content />

            <Footer />
        </div>
    );
}

//https://www.npmjs.com/package/react-hover-video-player




function Content() {
    const navigate = useNavigate()

    return (
        <div className='row px-5' style={{ height: '70vh', backgroundColor: '#37283D' }}>

            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                whileHover={{ scale: 0.95 }}
                className='col-12 col-lg px-4 pb-1'
            >

                <div onClick={() => navigate("/portfolio/2DArt")} className='h-100' style={{ cursor: 'pointer', backgroundImage: 'url(./art/ILLUSTRAZIONI/Reki_Kyan_Redraw.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='text-white d-flex flex-column h-100 justify-content-end'>
                        <h1 className='m-0 titoloPortfolio py-2'>2D ART</h1>
                    </div>
                </div>

            </motion.div>

            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                whileHover={{ scale: 0.95 }}
                className='col-12 col-lg px-4 pb-1'
            >
                <div onClick={() => navigate("/portfolio/3DArt")} className=' h-100' style={{ cursor: 'pointer', backgroundImage: 'url(./art/3d.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='text-white d-flex flex-column h-100 justify-content-end'>
                        <h1 className='m-0 titoloPortfolio py-2'>3D ART</h1>
                    </div>
                </div>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                whileHover={{ scale: 0.95 }}
                className='col-12 col-lg px-4 pb-1'
            >
                <div onClick={() => navigate("/portfolio/videogames")} className=' h-100' style={{ cursor: 'pointer', backgroundImage: 'url(./art/videogame.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='text-white d-flex flex-column h-100 justify-content-end'>
                        <h1 className='m-0 titoloPortfolio py-2'>VIDEOGAMES</h1>
                    </div>
                </div>
            </motion.div>

        </div >
    )

}

export default Portfolio;
