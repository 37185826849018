import Nav from 'react-bootstrap/Nav';

function Footer() {
    return (
        <div style={{ height: '10vh', backgroundColor: '#2F2332', zIndex: '1' }} className='text-white d-flex justify-content-center'>
            <div>
                <span style={{ fontSize: '0.8em' }} className='text-white d-flex flex-column justify-content-center h-100'>
                    <h5>©2023 BY ASTERLOCKE</h5>
                    <p style={{ margin: 0, padding: 0 }}>Website by <a target='_blank' style={{ color: 'white' }} href='https://bergamiluca.carrd.co/'>menek</a></p>
                </span>

            </div>
        </div>
    )
}
export default Footer