import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const useFirebase = () => {
    // Initialize Firebase
    const firebaseConfig = {
        apiKey: "AIzaSyC9424jwgRgGPQga51-FyrGwcd5n7iyhyY",
        authDomain: "asterlocke-art.firebaseapp.com",
        databaseURL: "https://asterlocke-art-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "asterlocke-art",
        storageBucket: "asterlocke-art.firebasestorage.app",
        messagingSenderId: "283418938326",
        appId: "1:283418938326:web:9a0d35added48abfd07277",
        measurementId: "G-PYS3XG7YJ7"
    };
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const auth = getAuth(app)


    return {
        db,
        auth,
        signInWithEmailAndPassword,
        signInWithCustomToken
    }
}

export default useFirebase