import "./App.css";
import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Homepage from "./page/homepage";
import Portfolio from "./page/portfolio";
import About from "./page/about";
import Contact from "./page/contact";
import Commissions from "./page/commissions";
import Art2d from "./page/portfolio/Art2d";
import Art3d from "./page/portfolio/Art3d";
import Videogames from "./page/portfolio/videogames";
import PrivateArea from "./page/private-area/privateArea";

function App() {
  useEffect(() => {
    const handleContextmenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", handleContextmenu);
    return () => document.removeEventListener("contextmenu", handleContextmenu);
  }, []);

  const getPageWithAnimation = (element) => (
    <motion.article
      initial={{ opacity: 0, transition: { duration: 0.5 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      {element}
    </motion.article>
  );

  const element = useRoutes([
    { path: "/", element: getPageWithAnimation(<Homepage />) },
    { path: "/portfolio", element: getPageWithAnimation(<Portfolio />) },
    { path: "/portfolio/2DArt", element: getPageWithAnimation(<Art2d />) },
    { path: "/portfolio/3DArt", element: getPageWithAnimation(<Art3d />) },
    { path: "portfolio/videogames", element: getPageWithAnimation(<Videogames />) },
    { path: "/about", element: getPageWithAnimation(<About />) },
    { path: "/contact", element: getPageWithAnimation(<Contact />) },
    { path: "/commissions", element: getPageWithAnimation(<Commissions />) },
    { path: "/privateArea", element: getPageWithAnimation(<PrivateArea />) }
  ]);

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait">
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}

export default App;
