import NavMenu from "./component/navMenu";
import Footer from "./component/footer";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

function Homepage() {
    const navigate = useNavigate();

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    const portfolio = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: 'white',
        backgroundColor: 'rgba(102, 68, 120, 0.83)',
        border: '3px solid white',
        borderRadius: '3px'
    };

    const [seconds, setSeconds] = useState(0);

    const array = [
        'url(./art/Banner/banner1.jpg)',
        'url(./art/Banner/banner2.jpg)',
        'url(./art/Banner/banner3.jpg)',
        'url(./art/Banner/banner4.png)',
        'url(./art/Banner/banner5.jpg)',
        'url(./art/Banner/banner6.jpg)',
        'url(./art/Banner/banner7.jpg)',
        'url(./art/Banner/banner8.jpg)',
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((seconds) => seconds + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ height: '100vh', backgroundColor: '#2F2332', overflow: 'hidden', zIndex: 0 }} className='App'>
            <NavMenu />
            <div style={{ height: '70vh' }}>
                <AnimatePresence mode='wait'>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 2 } }}
                        exit={{ opacity: 0 }}
                        className="App"
                        key={seconds}
                    >
                        <div className="w-100" style={{ height: '70vh', backgroundImage: array[seconds % array.length], backgroundSize: 'cover', backgroundPosition: 'center' }} />
                    </motion.div>
                </AnimatePresence>
                <div className="h-100 d-flex flex-column justify-content-center" style={{ position: 'absolute', top: '0', width: '100%' }}>
                    <div style={{ cursor: 'pointer' }} className="d-flex justify-content-center" onClick={() => navigate('/portfolio')}>
                        <h3 className="px-5 py-2" style={portfolio}>PORTFOLIO</h3>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Homepage;
