const images = [
    {
        image: "/art/ILLUSTRAZIONI/reki_peace_sign.png",
        title: "Reki peace sign",
        description: "ao",
        filter: ["ILLUSTRATION", "FANART"],
        otherImages: [],
    },
    {
        image: "/art/ILLUSTRAZIONI/Reki_Kyan_Redraw.png",
        title: "Reki kyan",
        description: "ao",
        filter: ["ILLUSTRATION", "FANART"],
        otherImages: [],
    },
    {
        image: "/art/ILLUSTRAZIONI/Ash_Kid.png",
        title: "Ash Kid",
        description: "Personaggio kotra molto woo",
        filter: ["ILLUSTRATION"],
        otherImages: [
            {
                image: "/art/ILLUSTRAZIONI/Ash_Kid_Dusk.png",
                title: "Ash Kid",
                description: "aaa",
            }, {
                image: "/art/ILLUSTRAZIONI/Ash_Kid_Dusk.png",
                title: "Ash Kid",
                description: "aaa",
            },
        ],
    },
    {
        image: "/art/SKETCHES/frog_MC.png",
        title: "FROEG",
        description: "cra cra bitch",
        filter: ["CONCEPT ART", "SKETCHES"],
        otherImages: [],
    },
    {
        image: "/art/CONCEPTART/shira_concept.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [
            {
                image: "/art/CONCEPTART/shira_sprites.png",
                title: "Shira Sprite",
                description: "aaa",
            },
        ],
    },
    {
        image: "/art/CONCEPTART/luke_human_concept.png",
        title: "Luke Human Concept",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [],
    },
    {
        image: "/art/CONCEPTART/matt_alessio_william_concept.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [],
    },
    {
        image: "/art/ILLUSTRAZIONI/IMG_LukeFinal_04.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [],
    },
    {
        image: "/art/CONCEPTART/aster_fullbody.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [
            {
                image: "/art/CONCEPTART/aster_details.png",
                title: "ao",
                description: "aaa",
            },
        ],
    },
    {
        image: "/art/ILLUSTRAZIONI/dnd_oc_delnaavi.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [],
    },
    {
        image: "/art/ILLUSTRAZIONI/kotra_oc_craberto.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [],
    },
    {
        image: "/art/ILLUSTRAZIONI/Ash_Grin.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [
            {
                image: "/art/ILLUSTRAZIONI/Ash_Side.png",
                title: "ao",
                description: "aaa",
            },
        ],
    },
    {
        image: "/art/CONCEPTART/aster_sickle.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [
            {
                image: "/art/CONCEPTART/aster_sickle_eye.png",
                title: "ao",
                description: "aaa",
            },
        ],
    },
    {
        image: "/art/CONCEPTART/Ash_First_Encounter.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [
            {
                image: "/art/CONCEPTART/Ash_Fight.png",
                title: "ao",
                description: "aaa",
            },
        ],
    },
    {
        image: "/art/CONCEPTART/Ash_Volkan.png",
        title: "Matt Alessio Willam",
        description: "Luke Human Concept",
        filter: ["CONCEPT ART"],
        otherImages: [],
    },
]

//
export default images
