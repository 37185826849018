import Footer from "../component/footer";
import NavMenu from "../component/navMenu";
import React from 'react';
import { motion } from "framer-motion";
import vg from '../data/vg';

const videogames = vg;

function Videogames() {
    return (
        <div style={{ backgroundColor: '#131419' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    );
}

function Content() {
    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    return (
        <div className="d-flex flex-column" style={{ minHeight: '70vh', overflowX: 'hidden', backgroundColor: '#37283D' }}>
            <div style={{ objectFit: 'cover', height: '10vh', backgroundImage: "url('/art/2d.jpg')" }}>
                <div style={centerMiddle}>
                    <h1 className="p-0 m-0 text-white">VIDEOGAMES</h1>
                </div>
            </div>
            <VideogameList array={videogames} />
        </div>
    );
}

function VideogameList({ array }) {
    return (
        <div className="d-flex justify-content-center">
            <div className="d-flex flex-column">
                {array.map((vg, index) => (
                    <Videogame key={index} vg={vg} />
                ))}
            </div>
        </div>
    );
}

function Videogame({ vg }) {
    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    return (
        <motion.div
            className="videogamesListElement"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.8,
                delay: 0.4,
                ease: [0, 0.71, 0.2, 1.01]
            }}
        >
            <a target="_blank" href={vg.url} className="d-flex my-4 text-decoration-none" style={{ height: '35vh', borderStyle: 'solid', borderWidth: '0px', borderColor: 'white', cursor: 'pointer' }}>
                <img className="h-100 p-2" src={vg.image} alt={vg.title} />
                <div className="p-5 text-white text-start" style={centerMiddle}>
                    <div className="d-flex flex-column">
                        <h1>{vg.title}</h1>
                        <p style={{ maxWidth: '50vw' }}>
                            {vg.description}
                        </p>
                    </div>
                </div>
            </a>
        </motion.div>
    );
}

export default Videogames;
