import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';

function NavMenu() {
    const navigate = useNavigate();

    const navItems = [
        { path: '/', label: 'Home' },
        { path: '/portfolio', label: 'Portfolio' },
        { path: '/about', label: 'About Me' },
        { path: '/contact', label: 'Contact' }
    ];

    return (
        <div style={{ height: '20vh', backgroundColor: '#2F2332', position: 'relative', zIndex: 15, width: '100%' }} className='d-flex flex-column justify-content-center'>
            <a style={{ cursor: 'pointer' }} onClick={() => navigate('/')} >
                <h1 style={{ color: 'white', padding: 0, margin: 0, fontSize: 50 }} className='TitleLogo'>asterlocke</h1>
            </a>
            <p className='text-white'>3d artist | concept artist</p>
            <Nav className='justify-content-center pt-2'>
                {navItems.map(item => (
                    <Nav.Item key={item.path} className='mx-3 text-white' onClick={() => navigate(item.path)} style={{ cursor: 'pointer', fontSize: '1.2em' }}>
                        {item.label}
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );
}

export default NavMenu;
