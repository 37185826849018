import Footer from "./component/footer"
import NavMenu from "./component/navMenu"
import React from "react";

function About() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}

function Content() {

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    return (
        <div className='row px-5 justify-content-center' style={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden', backgroundColor: '#37283D' }}>

            <div className="text-white pt-3">
                <h1>
                    About me
                </h1>
                <h5 className="font-light pt-3" style={{ paddingLeft: '15vw', paddingRight: '15vw' }}>
                    Hi! I'm Marti (or Aster), a 23 years old queer artist from Italy.
                    I like the high fantasy genre, D&D, videogames and punk music.
                    I want to create art and games to make people feel strong emotions 🙂
                </h5>
            </div>

            <img src="./art/Aster.jpg" style={{ height: '45vh', width: 'auto', backgroundColor: 'white', padding: '0.2rem', borderStyle: 'dotted', borderWidth: '0.2rem' }} />

        </div >
    )

}

export default About