import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { motion } from "framer-motion";
import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';

function ImageModal(props) {
    const [show, setShow] = useState(false);
    const [state, setState] = useState({
        imageLoading: true,
        pulsing: true
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let delay = Math.floor(Math.random() * 2000) + 2000;

    const imageLoaded = () => {
        setState({ imageLoading: false, pulsing: false });
    };

    function checkOtherImages() {
        if (props.img.otherImages.length !== 0) {
            return (
                <motion.div
                    animate={{ opacity: [0, 1], transition: { duration: 2 } }}
                >
                    <Carousel id='car' className='my-2 immmaginePortfolio' fade={true} controls={false} interval={delay} indicators={false} pause={false} onClick={handleShow}>
                        <Carousel.Item className='w-100' style={{ backgroundColor: state.imageLoading ? '#5E406E' : '' }}>
                            <motion.img
                                initial={{ height: "16rem", opacity: 0 }}
                                animate={{ height: state.imageLoading ? "16rem" : "auto", opacity: state.imageLoading ? 0 : 1 }}
                                transition={{ height: { delay: 0, duration: 0.4 }, opacity: { delay: 0.5, duration: 0.4 } }}
                                onLoad={imageLoaded}
                                width="100%"
                                src={props.img.image}
                                onClick={handleShow}
                            />
                        </Carousel.Item>
                        {!state.imageLoading && props.img.otherImages.map(ao => (
                            <Carousel.Item className='w-100' key={ao.image}>
                                <img src={ao.image} className='w-100' />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </motion.div>
            );
        } else {
            return (
                <div className={`${state.pulsing ? "pulse" : ""} w-100 my-2 immmaginePortfolio`} style={{ backgroundColor: state.imageLoading ? '#5E406E' : '' }}>
                    <motion.img
                        initial={{ height: "16rem", opacity: 0 }}
                        animate={{ height: state.imageLoading ? "16rem" : "auto", opacity: state.imageLoading ? 0 : 1 }}
                        transition={{ height: { delay: 0, duration: 0.4 }, opacity: { delay: 0.5, duration: 0.4 } }}
                        onLoad={imageLoaded}
                        width="100%"
                        src={props.img.image}
                        onClick={handleShow}
                    />
                </div>
            );
        }
    }

    function checkImage() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                size={props.img.otherImages.length !== 0 ? 'lg' : 'xl'}
                centered
                style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
                fullscreen={true}
            >
                <div onClick={handleClose} style={{ cursor: 'pointer', zIndex: 5, position: 'absolute', top: 0, right: 5, fontSize: '5rem' }} className='text-white'>
                    <Icon.X />
                </div>
                <Modal.Body style={{ height: '100vh', overflowY: 'hidden' }}>
                    <Carousel indicators={false} controls={props.img.otherImages.length !== 0} style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <Carousel.Item className='text-center w-100 h-100'>
                            <img src={props.img.image} style={{ maxWidth: '80vw', maxHeight: '75vh' }} />
                            <h2 className='text-white pt-4'>{props.img.title}</h2>
                            <p className='text-white'>{props.img.description}</p>
                            <div className='py-3'></div>
                        </Carousel.Item>
                        {props.img.otherImages.length !== 0 && props.img.otherImages.map(ao => (
                            <Carousel.Item className='text-center w-100' key={ao.image}>
                                <img src={ao.image} style={{ maxWidth: '80vw', maxHeight: '75vh' }} />
                                <h2 className='text-white pt-4'>{ao.title}</h2>
                                <p className='text-white'>{ao.description}</p>
                                <div className='py-3'></div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            {checkOtherImages()}
            {checkImage()}
        </>
    );
}

export default ImageModal;
