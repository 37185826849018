import React, { useState } from "react";

const ImageUploader = () => {
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      setMessage("Seleziona un'immagine prima di caricare.");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);

    try {
      const response = await fetch("https://portfoliomarti.altervista.org/upload.php", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      setMessage("Errore durante il caricamento.");
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Carica Immagine</button>
      <p>{message}</p>
    </div>
  );
};

export default ImageUploader;
