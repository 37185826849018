const filter = [
    'ALL',
    'ILLUSTRATION',
    'CONCEPT ART',
    'SKETCHES',
    'FANART'
]
const description = [
    'Tutti i miei lavori',
    'Solo le illustrazioni',
    'I concept wow che belli',
    'OOO cose sketchose',
    'Le fanart quelle belle'
]

let array = []
for (let i = 0; i < filter.length; i++) {
    array.push({
        filter: filter[i],
        description: description[i]
    })
}

const out = {
    filters: filter,
    descriptions: description,
    array: array
}

export default out
