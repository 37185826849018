const vg = [
    {
        image: '/art/avala.jpg',
        title: 'Cosmic Tribes: Avala',
        description: `A small demo for a bigger fantasy project.
        [Final University Project]`,
        url: 'https://asterlocke.itch.io/cosmic-tribes-avala'
    },
    {
        image: '/art/breakthecycle_banner.png',
        title: 'Break The Cycle',
        description: `You receive a message from your best friend Nia. She wants to go to a nearby forest fo a walk...`,
        url: 'https://wolfshira.itch.io/break-the-cycle'
    },
    {
        image: '/art/thumbnail.png',
        title: 'Healing potion',
        description: `Follow Dianthe on her adventure to find the ingredients to brew a healing potion.`,
        url: 'https://wolfshira.itch.io/healing-potion'
    },
    {
        image: '/art/thumbnail.png',
        title: 'Toybox Dream',
        description: `A small Marble Madness/Neverball-inspired level I made for a Unity Game Design exam for my academy. I decided to base it on a dreamy toy box full of different games. For now it's just a small level, but who knows... maybe it will become something more in the future.`,
        url: 'https://wolfshira.itch.io/healing-potion'
    }
]
export default vg