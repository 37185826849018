import Footer from "../component/footer";
import NavMenu from "../component/navMenu";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Images from "../data/images3d";
import filter from "../data/filter3d";
import { collection, getDocs } from "firebase/firestore";
import useFirebase from "../../hook/useFirebase";
import UploadImage from "../test/UploadImage";

const images = Images;
const filterList = filter;

function Art3d() {
    return (
        <div style={{ backgroundColor: '#131419' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    );
}

function Content() {

    const { db } = useFirebase()

    const [miao, setMiao] = useState('')

    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "test"))
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }))
            console.log('AO', data[0].data.img)
            setMiao(data[0].data.img)
        } catch (error) {
            console.error("MIAO", error)
        }
    }
    fetchData()


    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const [state, setState] = useState({
        filter: 'ALL',
        filterDescriptions: filterList
    });

    const getFilteredImages = () => {
        if (state.filter === 'ALL' || state.filter === '') {
            return images;
        } else {
            return images.filter(img => img.filter.includes(state.filter));
        }
    };

    return (
        <div className="d-flex flex-column" style={{ minHeight: '70vh', overflowX: 'hidden', backgroundColor: '#37283D' }}>
            <div style={{ objectFit: 'cover', height: '10vh', backgroundImage: "url('./art/2d.jpg')" }}>
                <div style={centerMiddle}>
                    <h1 className="p-0 m-0 text-white">3D ART</h1>
                </div>
            </div>
            <ButtonGroup callback={(filter) => setState({ ...state, filter })} />
            <Gallery array={getFilteredImages()} />

            <img src={'data:image/png;base64,' + miao}></img>
        </div>
    );
}

function ButtonGroup(props) {
    return (
        <div className="ButtonGroup py-3">
            {filterList.map((filtro, i) => (
                <React.Fragment key={i}>
                    <input defaultChecked={i === 0} className="FilterRadio" id={'filter' + i} type="radio" name='filter' onChange={() => props.callback(filtro)} />
                    <label className="FilterLabel px-4 py-1 m-0" htmlFor={'filter' + i}>{filtro}</label>
                </React.Fragment>
            ))}
        </div>
    );
}

function Gallery(props) {
    const navigate = useNavigate();

    const checkVideo = (img) => {
        if (img.video !== '') {
            return (
                <video className="w-100 my-2" autoPlay autoFocus muted loop>
                    <source src={img.video} type="video/mp4"></source>
                </video>
            );
        } else {
            return (
                <img className="w-100 my-2" onClick={() => {
                    sessionStorage.setItem('selectedProject', JSON.stringify(img));
                    navigate('/portfolio/project');
                }} src={img.image} alt={img.title} />
            );
        }
    };

    const grid = () => {
        const columns = [[], [], [], []];
        props.array.forEach((img, i) => {
            columns[i % 4].push(checkVideo(img));
        });
        return (
            <div className="row">
                {columns.map((col, i) => (
                    <div key={i} className="col p-2">
                        {col}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="px-5">
            {grid()}
        </div>
    );
}

export default Art3d;
